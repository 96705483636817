<template>
    <div></div>
</template>
<script>
export default {
    beforeCreate(){
        console.log({ query: this.$route.query });
        parent?.window?.mainpayComplete?.(this.$route.query);
        opener?.window?.mainpayComplete?.(this.$route.query);
        window.close();
    },
}
</script>